/*
 * React Virtualized Static Styles
 */

.ReactVirtualized__Table__headerRow {
  cursor: default !important;
}

.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

/*
 *  Radium doesn't play nice with react-virtualized, and so I had to add this
 *  style to the global css file.  To access, do the following
 *  <Table className="clickable" />
 */

.clickable div.preventHoverStyle:hover {
  cursor: default;
  background-color: #ffffff;
}

.clickable .ReactVirtualized__Table__row:hover {
  cursor: pointer;
  background-color: #fbfbfb;
}

.ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn .map-link {
  display: none !important;
}

.ReactVirtualized__Table__row:hover .ReactVirtualized__Table__rowColumn .map-link {
  display: flex !important;
}
